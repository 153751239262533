<template>
  <div class="wrapper">
    <div class="info">
      <div class="title">
        APPS WITH LOVE
      </div>
      <div class="description">
        <b>Who are we?</b><br/>
        A team of like-minded people who want to make life more convenient and useful.<br/>
        <b>What are we doing?</b><br/>
        We develop mobile applications that are in demand in the mobile market. We bring to life ideas that are missing, or those ideas that we can make much better and more convenient.<br/>
        <b>Our goal</b><br/>
        To make our every application the market leader in its segment. We want to help users to solve their problems with IT technologies.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "temp",
}
</script>
<style lang="less">
@import "style.less";
</style>